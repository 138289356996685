import React from 'react'
import { Container, Row, Col } from 'reactstrap';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { withPrefix } from 'gatsby';

const Imprint = (props) => (
    <Layout>
        <SEO googleTitle="Medoboard - optimiert Ihre Praxis"
            googleDescription="Medoboard organisiert Ihre Ordination mit intelligenten Funktionalitäten und optimiert Ihre Praxis."
            fbUrl="https://medoboard.com"
            fbImage={withPrefix('medoboard-fb.png')}
            fbTitle="Medoboard - optimiert Ihre Praxis"
            fbDescription="Medoboard unterstützt Sie in ihrer täglichen Arbeit mit dem Patienten damit Sie mehr Zeit für das Wesentliche haben"
            keywords={[`medoboard`, `Ordinationssoftware`, `intelligent`, `einfach`, `Patientenakte`, `Arztsoftware`]} />
        <Container>
            <Row>
                <Col md="12">
                    <h2 className="featurette-heading">Impressum</h2>
                    <h4 className="text-muted">23. Juli 2019</h4>
                </Col>
            </Row>
            <hr className="featurette-divider" />
            <Row>
                <Col md="12">
                    <p>Informationspflicht laut §5 E-Commerce Gesetz, §14 Unternehmensgesetzbuch, §63 Gewerbeordnung und Offenlegungspflicht laut §25 Mediengesetz.</p>
                    <p>
                        <b>Anschrift</b><br />
                        expressFlow GmbH<br />
                        Pater-Schwartz-Gasse 11A, <br/>
                        1150 Wien, <br />
                        Österreich
                    </p>
                    <p>
                        <strong>Unternehmensgegenstand: </strong> 
                        IT Dienstleistungen<br />
                        <strong>UID-Nummer:</strong> ATU 68618908<br />
                        <strong>Firmenbuchnummer:</strong> 413903 g<br />
                        <strong>Firmenbuchgericht:</strong> Wien<br />
                        <strong>Firmensitz:</strong> 1150 Wien
                    </p>
                    <p><strong>E-Mail:</strong> contact@expressflow.com
                    </p>
                    <p>
                        <strong>Mitglied bei: </strong> 
                        WKO<br />
                        <strong>Berufsrecht: </strong>Gewerbeordnung: www.ris.bka.gv.at</p>
                    <p>
                        <strong>Aufsichtsbehörde/Gewerbebehörde:</strong> Handelsgericht Wien<br />
                        <strong>Berufsbezeichnung:</strong> Software Entwicklung<br />
                        <strong>Verleihungsstaat:</strong> Österreich
                    </p>
                    <p>
                        <strong>Geschäftsführer</strong>
                        <br />
                    DI Dr. Martin Vasko
                    </p>
                </Col>
            </Row>
            <hr className="featurette-divider" />
        </Container>
    </Layout>
)

export default Imprint;